<template>
  <div class="members-directory">
    <v-container fluid class="pa-0">
      <template>
        <v-toolbar color="white" elevation="0" class="bt-top less-pd">
          <v-btn
            small
            @click="$router.push({ name: 'CarHistory' })"
            light
            min-height="40"
            class="
              post-reply-btn
              green
              darken-4
              green-gradient
              white--text
              create-post-btn
              mr-4
              pr-4
            "
            elevation="0"
            ><v-icon size="25" class="white--text text--darken-4 mr-4"
              >mdi-arrow-left-bold</v-icon
            >
            {{ $t("carHistory.back") }}
          </v-btn>
          <h2 class="green--text text--darken-4">
            {{ $t("carHistory.previousCarHistory") }}
          </h2>
          <v-spacer />
          <v-text-field
            hide-details="auto"
            v-model="search"
            elevation="0"
            height="40"
            dense
            :label="$t('posts.searchForWords')"
            solo
            @input="fetchPreviousCarHistory"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-toolbar>
      </template>
    </v-container>
    <ApiErrorMessage :message="apiErrorMessage" />
    <v-container fluid class="pa-0">
      <div class="custom-card">
        <div class="custom-box">
          <v-data-table
            :header-props="{ sortIcon: null }"
            :headers="headers"
            :items="previousCarHistory.data"
            height="calc(100vh - 366px)"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            fixed-header
            :disable-sort="true"
            :show-expand="false"
            class="color-green"
            :loading="loading"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
          >
            <!--  <template v-slot:item.pdf_path="{ item }">
              <span v-if="item.pdf_path"
                ><a class="view_receipt" @click="downlaodPDF(item.pdf_path)">
                  <v-icon size="35" class="green--text text--darken-4 mr-4"
                    >mdi-arrow-down-bold-circle</v-icon
                  ></a
                ></span
              >
            </template>-->
            <template v-slot:item.pdf_path="{ item }">
              <span v-if="item.pdf_path"
                ><a class="view_receipt" target="_blank" :href="item.pdf_path"
                  >{{ $t("messages.view") }}</a
                ></span
              >
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | DateTimezoneFilter }}
            </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="fetchPreviousCarHistory"
            ></v-pagination>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ITEMS_PER_PAGE } from "@/constants/common";
export default {
  name: "PreviousCarHistory",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      loading: false,
      page: 1,
      search: "",
      id: null,
      pageCount: 0,
      itemsPerPage: 0,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      headers: [
        {
          text: this.$t("carHistory.date"),
          align: "left",
          value: "created_at",
        },
        {
          text: this.$t("carHistory.vinNumber"),
          align: "left",
          value: "vin_number",
        },
        {
          text: this.$t("carHistory.pdfPath"),
          align: "left",
          value: "pdf_path",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      previousCarHistory: "carHistory/getPreviousCarHistoryReport",
    }),
  },
  async mounted() {
    await this.fetchPreviousCarHistory();
  },

  methods: {
    ...mapActions({
      getPreviousCarHistoryReport: "carHistory/getPreviousCarHistoryReport",
    }),

    downlaodPDF(url) {
      let link = document.createElement("a");
      link.href = url;
      link.download = "file.pdf";
      link.dispatchEvent(new MouseEvent("click"));
    },

    async fetchPreviousCarHistory() {
      this.loading = true;
      try {
        if (this.search !== "") {
          this.page = 1;
        }
        await this.getPreviousCarHistoryReport({
          search: this.search,
          items_per_page: ITEMS_PER_PAGE,
          page: this.page,
        });

        if (this.previousCarHistory.meta) {
          this.itemsPerPage = parseInt(this.previousCarHistory.meta.per_page);
          this.pageCount = this.previousCarHistory.meta.last_page;
        } else {
          this.itemsPerPage = this.previousCarHistory.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
